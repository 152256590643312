@import '../../../variables';
@import '../styles.scss';

.ant-menu.searchContent {
	padding-top: 5px;
	display: block;
	position: absolute;
	min-width: $searchWidth;
	text-align: left;
	list-style-type: none;
	background-color: $brandLightDark;
	font-family: $textFont;
	box-shadow: 10px 9px 14px 4px rgba(0,0,0,0.65);
	-webkit-box-shadow: 10px 9px 14px 4px rgba(0,0,0,0.65);
	-moz-box-shadow: 10px 9px 14px 4px rgba(0,0,0,0.65);
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 0px;
	max-height: 50vh; // TODO: Needs to be mobile optimized
	overflow-y: auto;

	&Mobile {
		@extend .searchContent;
		position: fixed;
		overflow-y: scroll;
		left: 0;
		width: 100%;
		margin-top: 0;
		border-radius: 0px;
		box-shadow: none;
		top: 80px;

		.searchMenuItem {
			padding: 0.5rem 1rem;
		}
	}
}

.searchMenuItem.ant-menu-item {
	max-width: 240px !important;
	white-space: pre-wrap;
	height: fit-content !important;

	&:hover,
	&:active,
	&:focus {
		color: $white;
		background-color: $brandLightDark;
	}

	&:hover {
		background-color: darken($brandLightDark, $hoverDarken);
		cursor: pointer;
	}

	&-active {
		color: $white;
		background-color: $brandLightDark;

		&:focus-visible, &:focus-within, &:target {
			box-shadow: none !important; // Extra highlight when selected by key
			outline: none !important;
			background-color: darken($brandLightDark, $hoverDarken);
		}		
	}

	

	// Our own div (not antd menu stuff)
	.searchMenuItemContainer {
		width: auto;
		display: grid;
		grid-template-columns: 50px 1fr;
		padding: 10px 0;
		transition: background-color 250ms ease;

		&:hover {
			background-color: darken($brandLightDark, $hoverDarken);
		}

		.colorBoxContainer {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	
		.textContainer {
			display: flex;
			flex-direction: column;

			p {
				margin: 2px;
			}

			.symbol {
				font-size: large;
			}

			.name {
				font-size: small;
			}
		}
	}
	
}
