@import '../../variables';

.elementView {
    display: grid;
    grid-template-columns: 300px repeat(2, minmax(160px, 1fr));
    margin: 40px 5vw;
    font-family: $textFont;
    column-gap: 5vw;

    .column {
        .elementDetailsContainer {
            margin-bottom: 25px;

            .elementProperty {
                margin-bottom: 8px;
                font-size: 18px;

                .titleSmall {
                    font-size: 0.65em;
                    color: $whiteGrey;
                }
            }

            .elementDetails {
                display: inline-block;
                margin: 0;
                //margin-left: 10px;
            }

            .colorBoxContainer {
                display: inline-flex;
                flex-direction: column;
                justify-content: center;

                .colorBox {
                    display: inline-block;
                }
            }

            .phaseIcon {
                width: 48px;
            }

            &.iconWithText {
                display: grid;
                grid-template-columns: 55px 1fr;
                margin-top: 10px;

                &:first-child {
                    margin-top: 30px;
                }

                .col {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

// Reset styling of links
a.noStyle {
    text-decoration: none;
    color: inherit;

    &:hover {
        text-decoration: none;
    }

    &:visited {
        color: inherit;
    }

    &:active {
        color: inherit;
    }
}