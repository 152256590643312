@import '../../variables';

nav#header {
	background-color: $brandLight;
	padding: 6px 30px;
	display: flex;
	flex-direction: row;

	.headerButton {
		display: block;
		height: 50px;
		background-color: $brandLight;
		color: $white;
		border: none;
		cursor: pointer;
		transition: background-color 250ms ease;
		flex: 1;

		&:hover {
			background-color: $brandDark;
		}

		&.size-1 {
			flex: 1;
		}
		&.size-2 {
			flex: 2;
		}
		&.size-3 {
			flex: 3;
		}
		&.size-4 {
			flex: 4;
		}
	}
}

.colorExplanation {
	display: none;

	&.show {
		display: inline-flex;
		flex-direction: row;
		align-items: stretch;
		flex: 2;
	}

	.colorContainer {
		display: inline-flex;
		justify-content: space-evenly;
		flex-direction: column;
		flex: 1;
		min-width: 40px;

		.colorBoxContainer {
			display: flex;
			justify-content: center;
		}

		p {
			//position: absolute;
			margin: 0;
			text-align: center;
		}
	}

	a {
		text-decoration: none;
		color: white;
		font-family: $tableFont;
		font-size: 14px;
	}
}
