$brandDark: #222222;
$brandLightDark: #292929;
$brandLight: #383838;
$white: #ffffff;
$whiteDark: #dcd9d5;
$whiteGrey: #b3b3b3;

$tableFont: 'Raleway', sans-serif;
$textFont: 'Quicksand', sans-serif;

$hoverDarken: 5%;