@import '../../variables';

a {
	text-decoration: none;
}

.elementContainer {
	aspect-ratio: 1 / 1;
	height: auto;
	border: 1px solid $whiteDark;
	padding: 4px;

	p {
		margin: 0;
		cursor: pointer;
		line-height: 1;
		color: $white;
		border: none;
		text-decoration: none;
		display: block;
		font-family: $tableFont;

		&:link &:visited,
		&:hover,
		&:active {
			color: $white;
			border: none;
			text-decoration: none;
		}
	}

	.id {
		font-size: 14px;
	}

	.electronegativity {
		display: none;
	}

	.symbol {
		font-size: 46px;
		text-align: center;
		text-shadow: none;
	}

	.name {
		text-align: center;
		font-size: 12px;
	}

	&.spacer {
		border: none;
		aspect-ratio: 2/1;
	}
}
