@import '../../variables';

.search {
	flex: 1;
	min-width: 100px;
	max-width: 240px;
	height: 40px;
	margin-top: auto;
	margin-bottom: auto;
	margin-right: 5px;
	z-index: 100;

	.inputForm {
		background-color: $brandLightDark;
		display: grid;
		grid-template-columns: 32px 1fr;
	
		> input,
		> svg {
			display: inline-block;
			background-color: $brandLightDark;
			color: $white;
			height: 28px;
		}
	
		> svg {
			width: 16px;
			padding: 0 8px;
			position: relative;
			top: 6px;
		}
	
		> input {
			border: none;
			height: 40px;
			padding: 0;
	
			&:focus {
				outline: none;
			}
		}
	}
	
	
	.searchResultsContainer {
		background-color: $brandLightDark;
		font-family: $textFont;
		box-shadow: 10px 9px 14px 4px rgba(0,0,0,0.65);
		-webkit-box-shadow: 10px 9px 14px 4px rgba(0,0,0,0.65);
		-moz-box-shadow: 10px 9px 14px 4px rgba(0,0,0,0.65);
		padding-top: 8px;
		padding-bottom: 8px;
		max-height: 50vh; // TODO: Need to be mobile optimized
		overflow-y: auto;

		a {
			color: $white;
			text-decoration: none;
			cursor: pointer;
		}

		a:focus {
			outline: none;
			
			.searchResult {
				background-color: darken($brandLightDark, $hoverDarken);
			}
		}

		p {
			margin: 0;
		}

		.searchResult {
			width: auto;
			display: grid;
			grid-template-columns: 50px 1fr;
			padding: 10px 0;
			transition: background-color 250ms ease;

			&:hover {
				background-color: darken($brandLightDark, $hoverDarken);
			}

			.colorBoxContainer {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}
	
			.textContainer {
				display: flex;
				flex-direction: column;

				.symbol {
					font-size: large;
				}

				.name {
					font-size: small;
				}
			}
		}
	}
}
