@import '../../variables';

.table {
	display: grid;
	grid-auto-flow: row;
	overflow-x: auto;
	margin-right: 40px;
	margin-top: -20px;
	margin-bottom: 50px;
	grid-template-columns: [period] 40px repeat(18, [element] minmax(80px, 1fr));
	grid-template-rows: repeat(2, minmax(80px, 1fr));

	.groupNumber,
	.periodNumber {
		display: flex;
		flex-direction: column;

		p {
			margin: 0;
			font-size: 16px;
			font-weight: bold;
			font-family: $tableFont;
		}
	}

	.periodNumber {
		justify-content: center;

		p {
			text-align: right;
			padding-right: 5px;
		}
	}

	.groupNumber {
		justify-content: flex-end;

		p {
			text-align: center;
			padding-bottom: 5px;
		}
	}
}
