@import '../../variables';
$searchFill: #3f565d;
$searchWidth: 240px;

@keyframes fade {
	from {
		opacity: 0.5;
		visibility: hidden;
		//transform: translateY(10px);
	}
	to {
		opacity: 1;
		visibility: visible;
		//transform: translateY(0);
	}
}

.search {
	flex: 1;
	width: $searchWidth;
	background: $brandLightDark;
	margin-right: 5px;
	margin-left: 5px;
	display: inline-block;

	.inputWrapper {
		display: flex;

		.input {
			border: initial;
			background: transparent;
			outline: initial !important;
			padding: 0.5rem 0.5rem;
			line-height: 1.75;
			font-size: 0.8rem;
			width: 93%;
			color: $white;
		}

		.iconWrapper {
			position: relative;
			width: 32px;
			height: 32px;

			.icon {
				position: absolute;
				top: 50%;
				left: 100%;
				transform: translate(-100%, -50%);
				border-radius: 10px;
				color: $white;
				
				svg {
					width: 17px;
					height: 17px;
					vertical-align: middle;
				}
			}
		}
	}

	&Mobile {
		@extend .search;
		display: flex;
		left: 0;
		right: 20px;
		position: absolute;
		width: 0px;
		z-index: 999;
		-webkit-transition: width 500ms;
		transition: width 500ms;

		button:focus {
			color: initial;
		}

		.inputWrapper {
			display: flex;
			width: 100%;

			.iconWrapperMobile {
				display: inline-block;
				margin: 9px 0px 0px 10px;
				height: 20px;
				width: 20px;

				.iconMobile {
					border-radius: 10px;
					width: 20px;
					height: 20px;
					cursor: pointer;
					color: #3f565d;

					svg {
						max-width: 20px;
						max-height: 20px;
					}
				}
			}
		}
		&Extended {
			@extend .searchMobile;
			width: 300px;
		}
	}
}

.ant-select {
	padding-top: 4px !important;
	padding-bottom: 4px !important;

	&.ant-select-disabled {
		.ant-select-selection-item {
			display: none;
		}
	}

	.ant-select-selection-overflow-item {
		.searchMenuItemContainer {
			.description,
			.tag {
				width: 0px; // If this is not done, the elements will be acounted for when calculating the width of the "tags" (after selection) and therefore, the tag will have a 100% width because of the content often is at least as wide as the input
			}
		}
	}
}

.ant-select-dropdown {
	.ant-select-item {
		.title {
			font-weight: bold;
			padding-bottom: 5px;
			color: #333 !important;
		}

		.description {
			font-size: 12px;
			padding-top: 5px;
			padding-bottom: 5px;
			color: #333 !important;
			line-height: normal !important;
		}

		.tag {
			margin-top: 8px;
			margin-bottom: 8px;
			line-height: normal !important;
		}
	}
}
