@import '../../variables';

.bigElementContainer {
    aspect-ratio: 1/1;
    width: 250px;
    height: auto;
	border: 1px solid $whiteDark;

    p {
        margin: 0;
        line-height: 1;
        color: $white;
        border: none;
        text-decoration: none;
        display: block;
        font-family: $tableFont;
    }

    /*
    .radioactive {
        width: 25%;
        position: relative;
        justify-self: flex-end;
    }
    */

    .id {
        font-size: 25px;
        padding: 3% 6%;
    }

    .symbol {
        font-size: 100px;
        text-align: center;
        margin-top: 20px;
    }

    .name {
        font-size: 20px;
        text-align: center;
        margin-top: 10px;
    }

    .mass {
        font-size: 14px;
        text-align: center;
        margin-top: 10px;
    }
}